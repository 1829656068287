/* src/components/StoreSelector.css */

.store-selector {
	margin-top: 20px;
	text-align: center;
}

.store-selector label {
	margin-right: 10px;
	font-weight: bold;
}

.store-selector select {
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.store-lock-message {
	margin-top: 10px;
	color: red;
	font-size: 0.9em;
}