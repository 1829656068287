/* src/styles.css */

body {
	font-family: Arial, sans-serif;
	background-color: #fafafa;
	margin: 0;
	padding: 0;
}

a {
	color: #007bff;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}